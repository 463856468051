import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseService,AuthService, UtilityService } from 'src/app/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit, OnDestroy {
  activeTab:any;
/* 
  tabs = [
    { "id": 1, "icon": "dashboard", "name": "dashboard", "route": "dashboard", "checked": true},
    { "id": 2, "icon": "groups", "name": "drivers", "route": "drivers","checked": false },
    { "id": 3, "icon": "local_shipping", "name": "vehicles", "route": "vehicles","checked": false },
    { "id": 4, "icon": "home_repair_service", "name": "driver-vehicle", "route": "driver-vehicle" ,"checked": false},
    { "id": 5, "icon": "report", "name": "reports", "route": "reports","checked": false },
    { "id": 6, "icon": "local_gas_station", "name": "fuel", "route": "fuel","checked": false },
    { "id": 7, "icon": "settings", "name": "settings", "route": "management","checked": false },
    { "id": 8, "icon": "pin_drop", "name": "vehicle-tracker", "route": "vehicle-tracker","checked": false },
  ] */
  tabs=[
    { "id": 1, "icon": "dashboard", "name": "dashboard", "route": "dashboard", "checked": true},
  ];
  mobileQuery: MediaQueryList;

  user:any;

  @ViewChild('snav') snav: MatSidenav | undefined;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private _router: Router,
    public jwtHelper: JwtHelperService,
    private _snackBar: MatSnackBar,
    private _auth: AuthService,
    private _utility: UtilityService,
    private _base: BaseService,
    private title:Title
  ) {
    title.setTitle('Logik Sense | Kurierdienst und Paketversand')
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.selectedControl();
    setTimeout(() => {
      this.snav!.open();
    }, 500);
    this.getUserInfo()
  }

  settings() {
    // this._bottomSheet.open(BottomSheetComponent);
  }

  logout() {
    localStorage.clear();
    this._utility.user.next(null);
    this._router.navigate(['auth/login']);
    
  }

  selectionChange(snav: any) {
    if (this.mobileQuery.matches) {
      snav.close();
    }
  }

  getUserInfo() {
    if (localStorage.getItem('auth-token')) {
       this._auth.userRoles=[localStorage.getItem("role")!]
       this._utility.companyId=parseInt(localStorage.getItem("cid")!);
       this._utility.language("de-DE");
      this._auth.getUserInfo().subscribe((_:any) => {
        if (_.result) {
          this._utility.user.next(_.data);
          this.user=_.data;
          this._auth.userRoles=[_.data.role.toLowerCase()];
          localStorage.setItem('role',_.data.role.toLowerCase());
          this.roleAuth();
          this.getTypes();
        }
      })
      this._utility.tokenInfo = this._auth.tokenInfo();
      this._auth.getApplicationRoles().subscribe((_:any) => {
        if (_.result) {
          this._utility.applicationRoles = _.data;
        }
      })
     
      if (this.jwtHelper.isTokenExpired(localStorage.getItem('auth-token')!)) {
        this._snackBar.open('Uygulamaya tekrar giriş yapmalısınız!', '', { panelClass: ['mat-toolbar', 'snack-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
        this._router.navigate(['auth/login']);
      }
    } else {
      this._snackBar.open('Uygulamaya giriş yapmalısınız!', '', { panelClass: ['mat-toolbar', 'snack-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
      this._router.navigate(['auth/login']);
    }
  }

  getTypes() {
    this._base.getSubcontractors(0, 500,this._utility.companyId).subscribe((_:any) => {
      if (_.data) {
        this._utility.subcontractorList = _.data
      }
    })
    this._base.getCurrency(0, 100,this._utility.companyId).subscribe((_:any) => {
      if (_.data) {
        this._utility.currencies = _.data
      }
    })
/*     this._base.getQuantityTypes(0, 100,this._utility.companyId).then((_:any) => {
      if (_.data) {
        this._utility.quantityTypes = _.data
      }
    }) */
    this._base.getVehicleBrand(this._utility.companyId,0, 500).subscribe((_:any) => {
      if (_.data) {
        this._utility.brands = _.data
      }
    })
  }
  roleAuth(){
    const role =localStorage.getItem('role');
    if(role){
       if(role=='driver'){
        this.logout();
        this._router.navigate(['auth/login'])
       }else if(role=='teamleader'){
/*         this.goToApp(environment.leadShiftUrl)
 */       }else if(role=='expert'){
        this.goToApp(environment.countbookUrl)
       }else if(role=='vehiclesupervisor'){
        this.tabs = [
          { "id": 1, "icon": "dashboard", "name": "dashboard", "route": "dashboard", "checked": true},     
          { "id": 3, "icon": "local_shipping", "name": "vehicles", "route": "vehicles","checked": false }, 
          { "id": 7, "icon": "settings", "name": "settings", "route": "management","checked": false },      
        ]
       }else{
        this.tabs = [
          { "id": 1, "icon": "dashboard", "name": "dashboard", "route": "dashboard", "checked": true},
          { "id": 2, "icon": "groups", "name": "drivers", "route": "drivers","checked": false },
          { "id": 3, "icon": "local_shipping", "name": "vehicles", "route": "vehicles","checked": false },
          { "id": 4, "icon": "home_repair_service", "name": "driver-vehicle", "route": "driver-vehicle" ,"checked": false},
          { "id": 5, "icon": "report", "name": "reports", "route": "reports","checked": false },
          { "id": 6, "icon": "local_gas_station", "name": "fuel", "route": "fuel","checked": false },
          { "id": 7, "icon": "settings", "name": "settings", "route": "management","checked": false },
          { "id": 8, "icon": "pin_drop", "name": "vehicle-tracker", "route": "vehicle-tracker","checked": false },
        ]
       }
    }else{
     this.tabs=[this.tabs[1],this.tabs[2],this.tabs[3],this.tabs[4],this.tabs[5]]
     localStorage.clear();
     this._router.navigate(['auth/login'])
    }
 }
 goToApp(url:any){
  window.location.assign(`${url}/apps/dashboard?token=${localStorage.getItem('auth-token')}&companyId=${localStorage.getItem('cid')}`);
}
selectedControl(){
  this.activeTab=this.tabs.find(t=>this._router.url.includes(t.route));
  if(this.activeTab){
    this.activeTab!.checked=true;
  }else{
    this.tabs[0].checked=true;
  }
}

}
