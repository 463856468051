import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from "@auth0/angular-jwt";
import { map, catchError} from 'rxjs/operators';
import { UtilityService } from '../utility.service';
import { ApiService } from '../network/api.service';
import { TokenService } from '../token.service';
import { Role } from '../../enums';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  gatewayApiUrl:string=environment.gatewayApiUrl;
  currentUser:any;
  userRoles: string[] = []; 

  constructor(private http: HttpClient, public jwtHelper: JwtHelperService,private _utility:UtilityService, private _api: ApiService,private _token:TokenService) { }
  
  login(data:any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    return this.http.post<any>(this.gatewayApiUrl+'/auth-service/token', data, { headers }).pipe(
      map((response) => {
       if (response) {
          this._token.saveToken(response.data.access_token);
          this._token.saveRefreshToken(response.data.refresh_token);
       }
       return response;
      }),
      catchError(error => {
        this._utility.snackbar(error.error,false);
        return error;
      })
    );
  }

  refreshToken(refreshToken:string): Observable<any> {
    return this._api.get(environment.gatewayApiUrl + "/auth-service/refresh-token?refreshToken="+refreshToken).pipe(map(data => data));
  }

  getUserInfo(): Observable<any> {
    let decoded = this.jwtHelper.decodeToken(localStorage.getItem('auth-token')!);
    return this._api.get(environment.gatewayApiUrl + "/auth-service/user/" + decoded.given_name).pipe(map(data => {this.currentUser=data; return data;}));
  }

  setLang(lang: any): Observable<any> {
    return this._api.get(environment.gatewayApiUrl + "/api/auth/SetLang?lang="+lang).pipe(map(data => data));
  }

   changePassword(pwd: any) {
    let decoded = this.jwtHelper.decodeToken(localStorage.getItem('auth-token')!);
    const model = {
      username: decoded.given_name,
      currentPassword: pwd.currentPassword,
      newPassword: pwd.newPassword
    }
    const result =  this._api.post(environment.gatewayApiUrl + "/auth-service/changepassword", model);
    return result;
  }

  update(model: any): Observable<any> {
    return this._api.post(environment.gatewayApiUrl + "/api/Auth/UpdateProfile", model).pipe(map(data => data));
  }
  
  get getUserRole(){
      const decoded =this.jwtHelper.decodeToken(this._token.getToken??'');
      const role =decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      if(decoded && role){
         switch (role) {
          case 'Admin':         
            return Role.Admin;
          case 'CompanyOwner':
            return Role.CompanyOwner;
          case 'CompanyAdmin':
            return Role.CompanyAdmin;
          case 'CompanyUser':
            return Role.CompanyUser;
          default:
            return Role.Empty;
         }
      }
      return Role.Empty;
  }

 

  tokenInfo() {
    let decoded = this.jwtHelper.decodeToken(localStorage.getItem('auth-token')!);
    return decoded
  }

   getUserList(page: number, limit: number,companyId:any,searchText:string) {

    const result =  this._api.get(environment.gatewayApiUrl + "/auth-service/getusers?page="+page+"&limit="+limit+"&companyId="+companyId+"&searchText="+(searchText??''));
    return result;
  }

   getUsersWithRole(page: number, limit: number,role:string,searchText:any) {
    const result =  this._api.get(environment.gatewayApiUrl + "/auth-service/getuserswithrole/"+role+"?page="+page+"&limit="+limit+"&searchText="+(searchText??''));
    return result;
  }

   getApplicationRoles() {
    const result =  this._api.get(environment.gatewayApiUrl + "/auth-service/roles");
    return result;
  }

   forgotPassword(model: any) {
    const result =  this._api.post(environment.gatewayApiUrl + "/auth-service/forgotpassword", model);
    return result;
  }

   resetPassword(model: any) {
    const result =  this._api.post(environment.gatewayApiUrl + "/auth-service/resetpassword", model);
    return result;
  }

   addApplicationUserWithRole(user: any) {
    const result =  this._api.post(environment.gatewayApiUrl + "/auth-service/adduserwithrole", user);
    return result;
  }

   deleteApplicationUser(username: any) {
    const result =  this._api.delete(environment.gatewayApiUrl+ "/auth-service/deleteuser?username="+username);
    return result;
    // const result =  this._api.delete(environment.authApiUrl + "/DeleteApplicationUser/" + username);
    // return result;
  }

   updateApplicationUserWithRole(user: any) {
    const model = {
      "firstName": user.name,
      "lastName": user.surname,
      "status": user.status,
      "role": user.role,
      "password": user.password,
      "userName": user.username,
      "email": user.email,
      "phoneNumber": user.phoneNumber,
      "authorizedFolders": user.authorizedFolders
    }
    const result =  this._api.post(environment.gatewayApiUrl + "/auth-service/updateuserwithrole", model);
    return result;
  }

   regionForUser(body:any) {
    const result =  this._api.post(`${environment.gatewayApiUrl}/user-service/regionforuser`,body);
    return result;
  }

   getRegionForUser(username:String) {
    const result =  this._api.get(`${environment.gatewayApiUrl}/user-service/regionforuser/${username}`);
    return result;
  }

   updateUserRegion(model: any) {
    const result =  this._api.put(environment.gatewayApiUrl + "/user-service/updateregionforuser", model);
    return result;
  }
   getUserAvatar(userName:any) {
    return  this._api.get(environment.gatewayApiUrl + "/auth-service/getuseravatar?userId="+userName);
  }
   uploadUserAvatar(model: any) {
    const result =  this._api.post(environment.gatewayApiUrl + "/auth-service/updateuseravatar", model);
    return result;
  }
  hasRole(roles: string[]): boolean {
    return roles.some(role => this.userRoles.includes(role));
  }
}
