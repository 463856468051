// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
    border-radius: var(--custom-border-radius);
    overflow: hidden;
    max-width: 100%;
    border-radius: 50%;
    cursor: pointer;
  }
  .avatar.image {
    object-fit: cover;
  }
  .avatar.no-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(200, 200, 200);
    border: 1px solid rgb(200, 200, 200);
    border-radius: 50%;
  }

  .avatar:hover {
    filter: opacity(0.5);
  -webkit-filter: opacity(0.5);
  }

  `, "",{"version":3,"sources":["webpack://./src/app/core/components/avatar/avatar.component.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oCAAoC;IACpC,oCAAoC;IACpC,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;EACtB,4BAA4B;EAC5B","sourcesContent":[".avatar {\n    border-radius: var(--custom-border-radius);\n    overflow: hidden;\n    max-width: 100%;\n    border-radius: 50%;\n    cursor: pointer;\n  }\n  .avatar.image {\n    object-fit: cover;\n  }\n  .avatar.no-image {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgb(200, 200, 200);\n    border: 1px solid rgb(200, 200, 200);\n    border-radius: 50%;\n  }\n\n  .avatar:hover {\n    filter: opacity(0.5);\n  -webkit-filter: opacity(0.5);\n  }\n\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
