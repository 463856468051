import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor() { }


getLocation(): Observable<string> {
    if (navigator.geolocation) {
      return new Observable<string>((observer) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = position.coords.latitude + ', ' + position.coords.longitude;
            observer.next(location);
            observer.complete();
          },
          (error) => {
            observer.error(this.showError(error));
          }
        );
      });
    } else {
      return throwError('Geolocation is not supported in this browser.');
    }
  }

  private showError(error:any): string {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return 'User denied the request for Geolocation.';
      case error.POSITION_UNAVAILABLE:
        return 'Location information is unavailable.';
      case error.TIMEOUT:
        return 'The request to get user location timed out.';
      case error.UNKNOWN_ERROR:
        return 'An unknown error occurred.';
      default:
        return 'An unexpected error occurred.';
    }
  }

}
