import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { ApiService } from 'src/app/core';;
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  apiUrl: String = environment.gatewayApiUrl;
  currentReport:any;
  currentReportType:any;
  private apolloFlp:ApolloBase
  constructor(
    private _api: ApiService,
    private _apollo:Apollo
  ) { 
    this.apolloFlp=_apollo.use('flp');
  }
   getList(page:number,limit:number){
    const result =  this._api.get(this.apiUrl +("/vehicle-report-service/get/")+page.toString()+"/"+limit.toString());
    return result;
  }
   getGarageList(page:number,limit:number){
    const result =  this._api.get(this.apiUrl +("/garage-service/get/")+localStorage.getItem('cid')+"/"+page.toString()+"/"+limit.toString());
    return result;
  }
  checkAppointmentDates(model:any): Observable<any>{
    return this._api.get(this.apiUrl +("/appointment-service/checkappointmentdates?companyId=")+localStorage.getItem('cid')+"&garageId="+model.garageId+"&startDate="+model.startDate+"&endDate="+model.endDate).pipe(map(data => data));
  }
   get(id:any){
    const result =  this._api.get(this.apiUrl +("/appointment-service/get/")+id);
    return result;
  }
   getByReportId(id:any){
    const result =  this._api.get(this.apiUrl +("/appointment-service/getForVehicleReportId/")+id);
    return result;
  }
   create(model:any){
    const result =  this._api.post(this.apiUrl + ("/appointment-service/create"), model);
    return result;
  }
   updateReportStatus(model:any){
    const result =  this._api.put(this.apiUrl + ("/vehicle-report-service/updatestatus"), model);
    return result;
  }
   createExternal(model:any){
    const result =  this._api.post(this.apiUrl + ("/appointment-service/createexternal"), model);
    return result;
  }
   update(model:any){
    const result =  this._api.put(this.apiUrl + ("/appointment-service/update"), model);
      return result;
  }
   delete(id:any){
      const result =  this._api.delete(this.apiUrl + ("/appointment-service/delete/")+id);
      return result;
  }
  vehicleReports(pageIndex:any,pageSize:any,companyId:any,searchText:string,vehicleReportType:number): Observable<any>{
    let accidentHistories=gql`
    {
      vehicleReports(pageIndex: ${pageIndex}, pageSize: ${pageSize},companyId:${companyId},searchText:${searchText==null ? null:'"'+searchText+'"'},vehicleReportType:${vehicleReportType}) {
        data {
          id
          description
          vehicleReportStatusType
          vehicleReport
          date
          driver{
            name
            surname
            identityNumber
          }
          vehicle {
            plateCode
          }
        } 
        paginationMetaData {
          page
          totalCount
        }
      }
    }
    `
    return this.apolloFlp.watchQuery<any>({query:accidentHistories}).valueChanges;
  
   }
  reportDetail(id:number): Observable<any>{
    let report=gql`
    {
      vehicleReport(id:${id})
         {
          id
          description
          date
          photos
          vehicleReportType
          vehicleReportStatus
          status
          driver{
            id
            name
            surname
            identityNumber
          }
          appointment{
            id
            appointmentStartDate
            appointmentEndDate
            status
          }
          vehicle {
            id
            plateCode
          }
      }
    }
    `
    return this.apolloFlp.watchQuery<any>({query:report}).valueChanges;
  
   }
}
