import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './network/api.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  apiUrl: String = environment.gatewayApiUrl;
  currentRegion: any;
  constructor(
    private _api: ApiService
  ) { }
  getList(model:any): Observable<any> {
    return this._api.post(this.apiUrl + "/api/Vehicle/GetUserVehicles", model).pipe(map(data => data));
  }
  get(id: any): Observable<any> {
    return this._api.get(this.apiUrl + "/api/Vehicle/GetUserVehicle?id=" + id).pipe(map(data => data));
  }
  create(model: any): Observable<any> {
    return this._api.post(this.apiUrl + "/api/Vehicle/CreateUserVehicle", model).pipe(map(data => data));
  }
  update(model: any): Observable<any> {

    return this._api.post(this.apiUrl + "/api/Vehicle/UpdateUserVehicle", model).pipe(map(data => data));
  }
  delete(id: any) {
    return this._api.get(this.apiUrl + "/api/Vehicle/DeleteUserVehicle?id=" + id).pipe(map(data => data.code==1));

  }
}
