// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apps-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.apps-is-mobile .apps-toolbar {
  position: fixed;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

h1.apps-app-name {
  margin-left: 8px;
}

.apps-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes \`<mat-sidenav-content>\` to act as our scrolling element for desktop layouts. */
  flex: 1;
}

.apps-is-mobile .apps-sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     \`<body>\` to be our scrolling element for mobile layouts. */
  flex: 1 0 auto;
}

.sidenav-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.header-image {
  background-image: url("https://image.freepik.com/free-vector/man-avatar-profile-round-icon_24640-14046.jpg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.icon-display {
  transform: scale(1.5);
}

::ng-deep .mat-list-option.mat-list-option .mat-list-text {
  padding-right: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/apps/apps.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AACJ;;AAEE;EACE,eAAA;EACA,8EAAA;EACA,UAAA;AACJ;;AAEE;EACE,gBAAA;AACJ;;AAEE;EACE;0FAAA;EAEA,OAAA;AACJ;;AAEE;EACE;+DAAA;EAEA,cAAA;AACJ;;AACE;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,8BAAA;AAEJ;;AAAE;EACE,4GAAA;EACA,6BAAA;EACA,4BAAA;EACA,sBAAA;EACA,kCAAA;AAGJ;;AAAE;EACE,qBAAA;AAGJ;;AADA;EACE,6BAAA;AAIF","sourcesContent":[".apps-container {\n    display: flex;\n    flex-direction: column;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n  }\n  \n  .apps-is-mobile .apps-toolbar {\n    position: fixed;\n    /* Make sure the toolbar will stay on top of the content as it scrolls past. */\n    z-index: 2;\n  }\n  \n  h1.apps-app-name {\n    margin-left: 8px;\n  }\n  \n  .apps-sidenav-container {\n    /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This\n       causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */\n    flex: 1;\n  }\n  \n  .apps-is-mobile .apps-sidenav-container {\n    /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the\n       `<body>` to be our scrolling element for mobile layouts. */\n    flex: 1 0 auto;\n  }\n  .sidenav-container{\n    display: flex;\n    width: 100%;\n    height: 100%;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n  .header-image {\n    background-image: url('https://image.freepik.com/free-vector/man-avatar-profile-round-icon_24640-14046.jpg');\n    background-color: transparent;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center center;\n  }\n  \n  .icon-display {\n    transform: scale(1.5);\n }\n::ng-deep .mat-list-option.mat-list-option .mat-list-text {\n  padding-right: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
