import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCommonInterceptor } from './interceptors/http.common.interceptor';
import {
  ApiService,
  AuthGuardService,
  AuthService,
  UtilityService,
  VehicleService,
  LoadingService,
  TokenService,
  ReportsService,
  AppointmentService,
  TranslateService
} from './services';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes';



@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpCommonInterceptor, multi: true },
    ApiService,
    AuthGuardService,
    AuthService,
    TokenService,
    UtilityService,
    LoadingService,
    VehicleService,
    ReportsService,
    AppointmentService,
    TranslateService,
  ],
  exports: [
    PipesModule,
    ComponentsModule
  ],
  declarations: [
  ]
})
export class CoreModule { }