// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// ng serve --configuration=dev
// ng build --configuration=dev

export const environment = {
  production: false,
  appName:"Logik Sense",
  companyName:"Logik Sense",
  slogan:"Kurierdienst und Paketversand",
  gatewayApiUrl:"https://flp.parklabtechnologie.com",
  s3Url:"https://flp-base-service.parklabtechnologie.com/api/v1/File/get/",
  graphqlUrl:"https://flp-aggregator-service.parklabtechnologie.com/graphql",
  flpUrl:"https://flp.parklab.app",
  leadShiftUrl:"https://leadshift.parklab.app",
  countbookUrl:"https://lager.parklab.app"
};

