import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, finalize, Observable, of, switchMap, take, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService, LoadingService, UtilityService } from '../services';
import { TokenService } from '../services/token.service';


@Injectable()
export class HttpCommonInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  activeRequests: number = 0;

  skippUrls = [
    '/auth/refresh-token',
  ];
  constructor(private loading: LoadingService, private _auth: AuthService,private router:Router ,private _token: TokenService,private _utility:UtilityService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Disabled selected urls for progress-bar
    let displayLoadingScreen = true;

    for (const skippUrl of this.skippUrls) {
      if (new RegExp(skippUrl).test(req.url)) {
        displayLoadingScreen = false;
        break;
      }
    }
    // Set Token
    var headersConfig: any = {
/*       'Content-Type': 'application/json',
 */      'Accept': 'application/json'
    };

    const token = this._token.getToken;
    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }
    const request = req.clone({ setHeaders: headersConfig });
    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.loading.startLoading();
      }
      this.activeRequests++;
      return next.handle(request).pipe(
        catchError(e => this.handleAuthError(e,request, next)),
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loading.stopLoading();
          }
        })
      )
    } else {
      return next.handle(request).pipe(catchError(e => this.handleAuthError(e, request, next)));
    }

  }

  private handleAuthError(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    //handle your auth error or rethrow
    if (err instanceof HttpErrorResponse && !request.url.includes('auth/login') && err.status === 401) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        const token = this._token.getRefreshToken;

        if (token)
          return this._auth.refreshToken(token).pipe(
            switchMap((token: any) => {
              this.isRefreshing = false;
              this._token.saveToken(token.access_token);
              this._token.saveRefreshToken(token.refresh_token);
              this.refreshTokenSubject.next(token.access_token);

              return next.handle(this.addTokenHeader(request, token.access_token));
            }),
            catchError((err) => {
              this.isRefreshing = false;
              this._token.signOut();
              this._utility.snackbar('tokenExpired',false,true);
              return throwError(() => err);
            })
          );
      }
      return  throwError(() => err);
    }
    if(err.status==403){
      this._token.signOut();
      this._utility.snackbar('accessDenied',false,true);
    }
    if(err.status==500 && request.url.includes('auth/refresh-token')){
      this._token.signOut();
      this._utility.snackbar('error',false,true);
    }
    this.loading.stopLoading();
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(req: HttpRequest<any>, token: string) {
    console.log(req)
    var headersConfig: any = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    const accessToken = token;

    if (accessToken) {
      headersConfig['Authorization'] = `Bearer ${accessToken}`;
    }
    const request = req.clone({ setHeaders: headersConfig });

    return request.clone({ setHeaders: headersConfig });
  }
}