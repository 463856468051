// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-tile {
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 10px;
  }
  .list-tile:hover{
    border-radius:45px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    cursor:pointer;

  }

  .content{
    display:flex;
    flex-direction:column;
    margin-left:1rem;
  }
  
  .leading {
    margin-left: 1rem;
  }

  .trailing {
    margin-right: 1rem;
  }
  
  .title {
    font-weight: bold;
  }
  
  .subtitle {
    color: gray;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/core/components/list-tile/list-tile.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,aAAa;EACf;EACA;IACE,kBAAkB;IAClB,0CAA0C;IAC1C,cAAc;;EAEhB;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".list-tile {\n    display: flex;\n    align-items: center;\n    justify-content:space-between;\n    padding: 10px;\n  }\n  .list-tile:hover{\n    border-radius:45px;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);\n    cursor:pointer;\n\n  }\n\n  .content{\n    display:flex;\n    flex-direction:column;\n    margin-left:1rem;\n  }\n  \n  .leading {\n    margin-left: 1rem;\n  }\n\n  .trailing {\n    margin-right: 1rem;\n  }\n  \n  .title {\n    font-weight: bold;\n  }\n  \n  .subtitle {\n    color: gray;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
