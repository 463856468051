// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar{
    display: flex;
    align-items: center;
    height: 60px;
    margin-top:1rem;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
    margin-right:1.5rem;
  }

  .spacer{
    flex: 1 1 auto;
  }`, "",{"version":3,"sources":["webpack://./src/app/core/components/toolbar/toolbar.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".toolbar{\n    display: flex;\n    align-items: center;\n    height: 60px;\n    margin-top:1rem;\n    margin-bottom: 1rem;\n    margin-left: 1.5rem;\n    margin-right:1.5rem;\n  }\n\n  .spacer{\n    flex: 1 1 auto;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
