import { Injectable } from '@angular/core';
import * as L from 'leaflet';
@Injectable({
  providedIn: 'root'
})
export class MapService {
  map: any;
  baseLayers: any;
  googleStreets: any;
  googleSat: any;
  currentLayerGroup: any;
  parkingLotLayerList: any = [];
  currentParkingLotList: any = [];
  currentRatio: String = '';
  zoomLevel: number = 10;
  vehicleIcon:any;
  constructor() { }

  initMap(): void {
    const m = this;
    this.googleStreets = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
    );
    this.googleSat = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
    );
    var zoomControl: boolean = true;

    this.map = L.map('map', {
      zoom: this.zoomLevel,
      layers: [this.googleSat],
      zoomControl: zoomControl,
      attributionControl: false,
      bounceAtZoomLimits: false
    });
    this.startingBounds();
    this.baseLayers = {
      Google: this.googleStreets,
      Satallit: this.googleSat
    };
    this.map.zoomControl.setPosition('bottomright');
    L.control
    .layers(this.baseLayers, undefined, { position: "bottomleft" })
    .addTo(this.map);
    this.currentLayerGroup=L.layerGroup();
    this.map.addLayer(this.currentLayerGroup);
    this.vehicleIcon = L.icon({
      iconUrl: '../../assets/red-vehicle-64.png',  
      iconSize:     [28, 28],
      iconAnchor:   [28, 28],
      popupAnchor:  [-8, -30]
  });
  }

  resizeMap() {
    this.map.invalidateSize();
  }

  startingBounds() {
    var southWest = L.latLng(47.2, 5.5),
      northEast = L.latLng(55.1, 15.5),
      bounds = L.latLngBounds(southWest, northEast);
    this.map.fitBounds(bounds);
  }

  addPointMarker(points: any): void {
   // pointLocation == 0 ? true : this.currentLayerGroup.clearLayers();
    var pointLocation = [];
    pointLocation.push(L.circleMarker(points, { color: "orange" }).on('click', (e: any) => { }).addTo(this.currentLayerGroup));
    this.map.setView(points, 16);
  }

  createHtmlMarker(lot: any) {
    let location = lot.location.split(', ')
    let icon = L.marker([location[0], location[1]], {
      icon: L.divIcon({
        className: 'available-icon',
        html: lot.id
      })
    }).bindPopup(this._lotPopUp(lot)).addTo(this.map);
    return icon
  }

  createCustomIconMarker(lot: any) {
    let location = lot.location.split(', ')
    let icon = L.marker([location[0], location[1]], {
      icon: this.vehicleIcon
    }).bindPopup(this._lotPopUp(lot)).addTo(this.map);
    return icon
  }

  createMarker(lot: any, ratio: any) {
    let location = lot.location.split(', ')
    let icon = L.marker([location[0], location[1]], {
      icon: L.divIcon({
        className: parseInt(ratio.split('%')[0]) <= 30 ? 'park-icon-30' : parseInt(ratio.split('%')[0]) <= 60 ? 'park-icon-30' : 'park-icon-90',
        html: ratio
      })
    }).on('click', (e: any) => this.clickMarker(location[0], location[1])).addTo(this.currentLayerGroup);
    return icon
  }

  clickMarker(lat: number, lng: number) {
    this.map.setView([lat, lng], 20);
  }

  addMarkerList(parkingLotList: any) {
    if(parkingLotList.length != 0){
      this.currentLayerGroup.clearLayers();
      this.parkingLotLayerList=[];
    }
    this.currentParkingLotList = parkingLotList;
    parkingLotList.forEach((lot: any) => {
      this.parkingLotLayerList.push(this.createCustomIconMarker(lot));
    });
  }

  openPopup(){
    this.parkingLotLayerList[0].openPopup();
  }
  closePopup(){
    this.map.closePopup();
  }

  private _lotPopUp(lot: any): any {
    return `
  <span style="font-size:16px;padding-bottom:8px"><b>Fahrzeug :</b> ${lot.vehicle.plateCode+' - '+lot.vehicle.brand+' '+lot.vehicle.model}</span><br>
  <span style="font-size:14px;padding-bottom:6px"><b>Fahrer :</b> ${lot.driver.name+' '+lot.driver.surname}</span><br>
  <span style="font-size:14px;padding-bottom:6px"><b>Zeit :</b> ${lot.eventDate.split('T')[0] +' '+lot.eventDate.split('T')[1]}  </span><br>
  `
  }

}
