import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  apiUrl: String = environment.gatewayApiUrl;
  currentReport:any;
  currentReportType:any;
  private apolloFlp:ApolloBase
  constructor(
    private _api: ApiService,
    private _apollo:Apollo
  ) { 
    this.apolloFlp=_apollo.use('flp');
  }
   getList(page:number,limit:number){
    const result =  this._api.get(this.apiUrl +("/vehicle-report-service/get/")+page.toString()+"/"+limit.toString());
    return result;
  }
   get(id:any){
    const result =  this._api.get(this.apiUrl +("/vehicle-report-service/get/")+id);
    return result;
  }
   create(model:any){
    const result =  this._api.post(this.apiUrl + ("/vehicle-report-service/create"), model);
    return result;
  }
   createExternal(model:any){
    const result =  this._api.post(this.apiUrl + ("/appointment-service/createexternal"), model);
    return result;
  }
   update(model:any){
    const result =  this._api.put(this.apiUrl + ("/vehicle-report-service/update/"), model);
      return result;
  }
   delete(id:any){
      const result =  this._api.delete(this.apiUrl + ("/vehicle-report-service/delete/")+id);
      return result;
  }
  vehicleReports(pageIndex:any,pageSize:any,companyId:any,searchText:string,vehicleReportType:number): Observable<any>{
    let accidentHistories=gql`
    {
      vehicleReports(pageIndex: ${pageIndex}, pageSize: ${pageSize},companyId:${companyId},searchText:${searchText==null ? null:'"'+searchText+'"'},vehicleReportType:${vehicleReportType}) {
        data {
          id
          description
          vehicleReportType
          vehicleReportStatus
          date
          driver{
            id
            name
            surname
            identityNumber
          }
          vehicle {
            id
            plateCode
          }
        } 
        paginationMetaData {
          page
          totalCount
        }
      }
    }
    `
    return this.apolloFlp.watchQuery<any>({query:accidentHistories,fetchPolicy: 'no-cache'}).valueChanges;
  
   }
  reportDetail(id:number): Observable<any>{
    let report=gql`
    {
      vehicleReport(id:${id})
         {
          id
          description
          date
          photos
          vehicleReportType
          vehicleReportStatus
          status
          driver{
            id
            name
            surname
            identityNumber
          }
          appointment{
            id
            appointmentStartDate
            appointmentEndDate
            status
          }
          vehicle {
            id
            plateCode
          }
      }
    }
    `
    return this.apolloFlp.watchQuery<any>({query:report}).valueChanges;
  
   }
}
