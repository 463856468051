import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  apiUrl: String = environment.gatewayApiUrl;

  constructor(
    private _api: ApiService,
    private _http: HttpClient

  ) { }

   getCustomFields(page: number, limit: number): Observable<any> {
 
    const result =  this._api.get(this.apiUrl + "/base-service/getcustomfields?page="+page+"&limit="+limit);
    return result;
  }
   getCurrency(page: number, limit: number,companyId:any): Observable<any> {
    const result =  this._api.get(this.apiUrl + "/base-service/getcurrency?page="+page+"&limit="+limit+"&companyId="+companyId);
    return result;
  }
   addCurrency(model: any): Observable<any> {
    const result =  this._api.post(this.apiUrl + "/base-service/addcurrency", model);
    return result;
  }
   updateCurrency(model: any): Observable<any> {
    const result =  this._api.post(this.apiUrl + "/base-service/updatecurrency", model);
    return result;
  }
   deleteCurrency(id: any): Observable<any> {
    let params = new HttpParams()
      .set("id", id)
    const result =  this._api.delete(this.apiUrl + "/base-service/deletecurrency?id="+id);
    return result;
  }
   getQuantityTypes(page: number, limit: number,companyId:any): Observable<any> {
    const result =  this._api.get(this.apiUrl + "/base-service/getquantitytypes?page="+page+"&limit="+limit+"&companyId="+companyId);
    return result;
  }

   getSubcontractors(page:number,limit:number,companyId:number){
    const result =  this._api.get(this.apiUrl + "/subcontractor-service/get/"+companyId+"/"+page.toString()+"/"+limit.toString());
    return result;
  }

   addQuantityType(model: any): Observable<any> {
    const result =  this._api.post(this.apiUrl + "/base-service/addquantitytype", model);
    return result;
  }
   updateQuantityType(model: any): Observable<any> {
    const result =  this._api.post(this.apiUrl + "/base-service/updatequantitytype", model);
    return result;
  }
   deleteQuantityType(id: any): Observable<any> {

    const result =  this._api.delete(this.apiUrl + "/base-service/deletequantitytype?id="+id);
    return result;
  }
   getTags(page: number, limit: number): Observable<any> {
    const result =  this._api.get(this.apiUrl + "/base-service/gettags?page="+page+"&limit="+limit);
    return result;
  }
  getTagSuggestionNames(path: string): Observable<any> {
    const token = localStorage.getItem('auth-token');
    var httpHeaders: any;
    if (token != null) {
      httpHeaders = new HttpHeaders().set(
        "Authorization",
        "Bearer " + token
      );
    }
    let options = {
      headers: httpHeaders
    };
    return this._http.get<any>(this.apiUrl + "/base-service/gettagsuggestionnames/" + path, options)
  }
   AddTag(model: any): Observable<any> {
    const result =  this._api.post(this.apiUrl + "/base-service/addtag", model);
    return result;
  }
   UpdateTag(model: any): Observable<any> {
    const result =  this._api.post(this.apiUrl + "/base-service/updatetag", model);
    return result;
  }
   DeleteTag(id: any): Observable<any> {
    let params = new HttpParams()
      .set("id", id)
    const result =  this._api.delete(this.apiUrl + "/base-service/deletetag?id="+ id);
    return result;
  }

   getVehicleBrand(companyId:any,page: number, limit: number): Observable<any> {
    const result =  this._api.get(this.apiUrl + "/vehiclebrand-service/get/"+companyId+'/'+page+'/'+limit);
    return result;
  }

   getVehicleModel(companyId:any,brandId:number,page: number, limit: number): Observable<any> {
    const result =  this._api.get(this.apiUrl + "/vehiclemodel-service/get/"+companyId+'/'+brandId+'/'+page+'/'+limit);
    return result;
  }

}
